import React from 'react';
import { useEffect, useState } from 'react';
import { isItUndefined } from '../../../util/DataUtil';
import { Breadcrumb } from '../breadcrumb';
import { useRouter } from 'next/router';
import { getCookie } from 'cookies-next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { Constants } from '../../../util/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { setScreenSize } from '../../../store/pageSlice';
import Header from '../header/header';
import { ixnRedirectMessages } from './ixnRedirectMessages';
import {
  getScriptForLanguage,
  getDomainAlias,
  getCanonical,
  getLanguageContent,
  renderAlternateLinks,
  onScroll,
  getLandPageFromRedirect,
  removeQueryParams,
  getDevice,
  setOperatingSystemClass,
} from '../../../util/LayoutUtil';
import { extractMetaTags, renderMetaTags } from './layoutMetaUtils';
const Footer = dynamic(() => import('../footer'));

const Layout = ({
  urlAlias,
  data,
  meta,
  lang,
  children,
  menuData,
  footerData,
  globalMetrics,
  countryData,
  endpoint,
  contentType,
}) => {
  const hideElements = useSelector((state) => state.pageSlice);
  const isBrowser = typeof window == 'undefined';
  const [viewportWidth, setViewportWidth] = useState(
    !isBrowser ? window?.innerWidth : 0
  );
  const dispatch = useDispatch();
  const heroBlock = data?.field_template_blocks?.find((x) => x?.hero_block);
  const host = useSelector((state) => state.host.host);
  const [isIxnquery, setisIxnQuery] = useState(false);
  const [device, setDevice] = useState();
  const { metaTags, canonicalUrl, titleTag, description } =
    extractMetaTags(data);
  const existingPages = data.translang;
  useEffect(() => {
    setDevice(getDevice(viewportWidth));
  }, [viewportWidth]);

  useEffect(() => {
    const languageToFontId = {
      ja: 'japanese-font',
      'zh-hans': 'chinese-font',
      ko: 'korean-font',
    };

    document.body.id = languageToFontId[lang] || '';
  }, [lang]);

  useEffect(() => {
    if (isIxnquery) {
      removeQueryParams();
      const timeoutId = setTimeout(() => {
        setisIxnQuery(false);
      }, 8000);

      return () => clearTimeout(timeoutId);
    }
  }, [isIxnquery]);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };
    if (!isIxnquery) {
      const scrollbarThumb = document.querySelector('.overlay-scrollbar-thumb');
      const scrollbarTrack = document.querySelector('.overlay-scrollbar-track');

      scrollbarThumb.addEventListener('mousedown', (event) => {
        event.preventDefault();
        const startY = event.pageY;
        const startScrollTop = document.documentElement.scrollTop;

        document.addEventListener('mousemove', moveHandler);
        document.addEventListener('mouseup', cleanup);

        function moveHandler(event) {
          event.preventDefault();
          const delta = event.pageY - startY;
          document.documentElement.scrollTop = startScrollTop + delta / 1.15;
        }

        function cleanup() {
          document.removeEventListener('mousemove', moveHandler);
          document.removeEventListener('mouseup', cleanup);
        }
      });

      scrollbarTrack.addEventListener('mousedown', (event) => {
        event.preventDefault();
        const deltaY = event.offsetY - scrollbarThumb.offsetHeight / 2;
        document.documentElement.scrollTop += deltaY;
      });

      window.addEventListener('resize', handleResize);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(setScreenSize(viewportWidth));
  }, [viewportWidth]);

  let origin = undefined;
  const router = useRouter();
  let sendHome = false;
  const [showBg, setShowBg] = useState(false);
  const [showSearchBg, setShowSearchBg] = useState(false);

  let adminCookie = getCookie('adminUser');

  if (
    data.published !== '1' &&
    (typeof adminCookie === 'undefined' || adminCookie === '{}')
  ) {
    sendHome = true;
  }

  useEffect(() => {
    if (sendHome) {
      sendHome = false;
      router.push('/');
    }
  }, [adminCookie]);

  if (typeof window !== 'undefined') {
    origin = window.location.origin;
  }

  useEffect(() => {
    const { query: queryParams } = router;
    if (queryParams.hasOwnProperty('inx')) {
      setisIxnQuery(true);
    }
  }, [router.query]);

  useEffect(() => {
    window.addEventListener('scroll', updateCustomScrollbar(), {
      passive: true,
    });

    // replaceOnDocument(/&amp;/g, '&');
  }, []);

  const toggleBg = () => {
    setShowBg(!showBg);
  };

  const offBg = () => {
    setShowBg(false);
  };

  const onBg = () => {
    setShowBg(true);
  };

  const updateCustomScrollbar = () => {
    window.addEventListener('scroll', onScroll(), { passive: true });

    return () => {
      window.removeEventListener('scroll', onScroll(), { passive: true });
    };
  };

  const toggleShowSearchBg = (searchActive) => {
    setShowSearchBg(searchActive);
  };

  useEffect(() => {
    setOperatingSystemClass();
  }, []);

  return (
    <>
      {!isBrowser && (
        <Head>
          {getScriptForLanguage(lang)}
          <script
            dangerouslySetInnerHTML={{
              __html: `
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=ErWcGdQjoYB34UBL4wE3QA&gtm_preview=env-961&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-T2MV27');
                    `,
            }}
          />
          <link rel="shortcut icon" href="/favicon/favicon1.png" />
          <title>
            {titleTag ? titleTag : 'Digital Realty | Data Center Services'}
          </title>
          {renderAlternateLinks(existingPages, Constants)}
          <link href={Constants[getDomainAlias(lang)]} rel="home" />
          {getCanonical(canonicalUrl, lang, urlAlias)}
          <meta name="urlAlias" content={urlAlias} />
          <meta name="language" content={getLanguageContent(lang)} />
          {lang === 'ko' ? (
            <meta
              name="naver-site-verification"
              content="naver298f5ae2747288292ba000078130e694"
            />
          ) : (
            ''
          )}
        </Head>
      )}
      {isBrowser && (
        <Head>
          {getScriptForLanguage(lang)}
          <script
            dangerouslySetInnerHTML={{
              __html: `
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=ErWcGdQjoYB34UBL4wE3QA&gtm_preview=env-961&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-T2MV27');
                    `,
            }}
          />
          <title>
            {titleTag ? titleTag : 'Digital Realty | Data Center Services'}
          </title>
          {renderMetaTags(metaTags)}
          {renderAlternateLinks(existingPages, Constants)}
          <link href={Constants[getDomainAlias(lang)]} rel="home" />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={origin ? origin + '/favicon/apple-touch-icon.png' : ''}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href={origin ? origin + '/favicon/favicon-32x32.png' : ''}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href={origin ? origin + '/favicon/favicon-16x16.png' : ''}
          />
          <link rel="shortcut icon" href="/favicon/favicon1.png" />
          <link
            rel="manifest"
            href={origin ? origin + '/favicon/site.webmanifest' : ''}
          />
          {heroBlock ? (
            <meta
              key={`meta-hero-banner}`}
              name={'og:image'}
              content={`https://res.cloudinary.com/digitalrealty/image/upload/${heroBlock?.hero_block?.field_hero_image?.webimage}`}
            ></meta>
          ) : (
            <></>
          )}
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff" />
          <meta
            name="keywords"
            content={meta ? meta[0]?.field_meta_tags_keywords : ''}
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1"
          />
          <script
            src="https://kit.fontawesome.com/f5ffb738a2.js"
            crossOrigin="anonymous"
          />
          <link
            rel="stylesheet"
            href="https://kit.fontawesome.com/f5ffb738a2.css"
            crossOrigin="anonymous"
          />
          <script src="https://smtpjs.com/v3/smtp.js" />
          {getCanonical(canonicalUrl, lang, urlAlias)}
          <meta name="urlAlias" content={urlAlias} />
          <meta name="language" content={getLanguageContent(lang)} />
          {lang === 'ko' ? (
            <meta
              name="naver-site-verification"
              content="naver298f5ae2747288292ba000078130e694"
            />
          ) : (
            ''
          )}
        </Head>
      )}

      {isIxnquery && lang !== 'en' ? (
        getLandPageFromRedirect(lang, device, ixnRedirectMessages)
      ) : (
        <>
          <noscript
            dangerouslySetInnerHTML={{
              __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T2MV27&gtm_auth=ErWcGdQjoYB34UBL4wE3QA&gtm_preview=env-961&gtm_cookies_win=x"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
            }}
          ></noscript>
          <div className={showBg ? 'gray-bg active' : 'gray-bg'} />
          <div
            className={
              showSearchBg ? 'show-search-bg active' : 'show-search-bg'
            }
          />
          <Header
            urlAlias={urlAlias}
            endpoint={endpoint}
            screenWidth={viewportWidth}
            countryData={countryData}
            pageTranslationPaths={children?.props?.data?.translang}
            hideHeader={isItUndefined(data?.field_hide_footer)}
            toggleShowSearchBg={toggleShowSearchBg}
            onBg={onBg}
            offBg={offBg}
            globalMetrics={globalMetrics}
            lang={lang}
            data={menuData}
          />
          {!hideElements?.hideBreadCrumbs && (
            <Breadcrumb
              contentType={contentType}
              hideHeader={isItUndefined(data?.field_hide_footer)}
              urlAlias={urlAlias}
              data={data}
              lang={lang}
            />
          )}
          <div className="overlay-scrollbar-track">
            <div className="overlay-scrollbar-thumb"></div>
          </div>
          {sendHome ? '' : <main>{children}</main>}
          {!hideElements?.hideFooter && (
            <Footer
              hideFoooter={isItUndefined(data?.field_hide_footer)}
              data={footerData}
              globalMetrics={globalMetrics}
              lang={lang}
              endpoint={endpoint}
            />
          )}
        </>
      )}
    </>
  );
};

export default Layout;
