import React, { useEffect, useState } from 'react';
import { isItUndefined, modifyLinks } from '../util/DataUtil';
import Link from 'next/link';
import { Constants } from '../util/Constants';
import { getEditorColor } from '../util/ColorUtil';
import { cleanHTML } from './utils/htmlUtils';

export const ConditionalBlock = ({ data, lang }) => {
  const emphasizedTextColor = getEditorColor(
    isItUndefined(data?.field_title_color)
  );
  const [isHoverActive, setIsHoverActive] = useState(false);
  const [isKnownUser, setIsKnownUser] = useState(false);

  const ctaText = data?.field_cta_text?.[0]?.value;
  const ctaUrl = data?.field_cta_url?.[0]?.value;
  const industrySpotlightText = data?.field_industry_spotlight_text?.[0]?.value;
  //UNKNOWN USER
  let industrySpotlight = data?.field_industry_spotlight?.[0]?.value;
  const unknownUserDynamicValue = data?.field_dynamic_value?.[0]?.value;
  //KNOWN USER
  const targetDataLayerKey =
    data?.field_filter_data_layer?.[0]?.parent_taxonomy_data?.name;
  const targetDataLayerValue = data?.field_filter_data_layer?.[0]?.name;
  const knownUserIndustrySpotlight =
    data?.field_filter_data_layer?.[0]?.field_industry_spotlight;
  let dynamicValue = data?.field_filter_data_layer?.[0]?.field_data_layer_value;

  console.log('key', targetDataLayerKey);
  console.log('value', targetDataLayerValue);

  const updateDataLayerValue = () => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      const item = window.dataLayer.find(
        (item) => item[targetDataLayerKey] === targetDataLayerValue
      );

      console.log('DataLayer contents:', window.dataLayer); // Log entire dataLayer
      console.log('Item found in dataLayer:', item); // Log the specific item

      if (item) {
        setIsKnownUser(true);
        console.log('Known user detected:', item[targetDataLayerKey]);
      } else {
        console.log(
          'No matching item found for key:',
          targetDataLayerKey,
          'value:',
          targetDataLayerValue
        );
      }
    } else {
      console.log('dataLayer is not available on the window object');
    }
  };

  useEffect(() => {
    // Initial check for dataLayer value
    updateDataLayerValue();

    // Listen for new pushes to the dataLayer
    const dataLayerListener = (event) => {
      if (
        event.detail &&
        event.detail[0][targetDataLayerKey] === targetDataLayerValue
      ) {
        setDataLayerValue(event.detail[0][targetDataLayerKey]);
      }
    };

    window.addEventListener('dataLayerPush', dataLayerListener);
    return () => window.removeEventListener('dataLayerPush', dataLayerListener);
  }, [targetDataLayerKey, targetDataLayerValue]);

  let personalizationData = {};

  personalizationData = {
    p_type: 'full_width_block',
    p_focus: dynamicValue,
    p_content: dynamicValue,
    p_button_text: ctaText,
    p_button_link: ctaUrl,
  };

  // Function to handle CTA click and push full data object to the dataLayer
  const handleCTAInteraction = () => {
    if (typeof window !== 'undefined' && window.dataLayer && isKnownUser) {
      const completeData = {
        event: 'personalization_block',
        ...personalizationData,
        page_referrer: document.referrer,
        page_location: window.location.href,
        page_title: document.title,
      };
      window.dataLayer.push(completeData);
      console.log('Data pushed to dataLayer:', completeData);
    }
  };

  let modifiedHTML = cleanHTML(modifyLinks(data?.field_title_color[0]?.value));

  // Check if there are any <p> tags; if so, insert message inside the last <p> tag
  if (modifiedHTML.includes('<p>') && modifiedHTML.includes('</p>')) {
    // Find the last closing </p> and insert message with <span> tag before it
    const lastParagraphIndex = modifiedHTML.lastIndexOf('</p>');
    modifiedHTML = `${modifiedHTML.slice(0, lastParagraphIndex)} <span style="color: #00d87d">${isKnownUser ? dynamicValue : unknownUserDynamicValue}</span>${modifiedHTML.slice(lastParagraphIndex)}`;
  } else {
    // If no <p> tags are found, just append the message at the end
    modifiedHTML += ` <span style="color: #00d87d">${isKnownUser ? dynamicValue : unknownUserDynamicValue}</span>`;
  }

  return isKnownUser ? (
    <div
      className={
        'a-full-width-image-banner-link full-width-image-banner conditional-block'
      }
      onMouseOver={() => setIsHoverActive(!isHoverActive)}
      onMouseOut={() => setIsHoverActive(!isHoverActive)}
      style={{
        backgroundImage:
          'url(' +
          (Constants.cloudinaryImage + data?.field_full_width_image[0]) +
          ')',
      }}
      onClick={() => {
        window.open(ctaUrl, '_blank');
      }}
    >
      <div className={'helper-left-margin-container'}>
        <div className="industry-spotlight">
          <span className="industry-icon">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.8621 0.5H7.82761V1.7931H8.8621V0.5ZM9.89653 1.79313L9.9001 1.79313H10.1552V1.82572C10.6013 1.94057 10.931 2.34559 10.931 2.82761V5.67244H9.89653V2.82761L5.5 2.82761V1.79313L9.89653 1.79313V1.79313ZM7.82761 15.5V14.2069H6.53448C6.05246 14.2069 5.64744 13.8772 5.5326 13.431H5.5V13.1724V9.81034H6.53448V13.1724H10.8534V14.2069H8.8621V15.5H7.82761ZM9.81894 9.97605L5.51528 5.67239H5.5V5.65711V3.86205H6.53448V5.22861L10.8486 9.54271L10.8396 9.5517H10.8534V12.1379H9.81894V9.97605Z"
                fill="black"
              />
            </svg>
          </span>
          <p>
            {industrySpotlightText}{' '}
            {isKnownUser ? knownUserIndustrySpotlight : industrySpotlight}
          </p>
        </div>
        <span
          className={`title ${lang === 'de' ? 'title-de' : ''} ${lang === 'nl' ? 'title-nl' : ''} ${emphasizedTextColor ? emphasizedTextColor : ''}`}
          dangerouslySetInnerHTML={{
            __html: modifiedHTML,
          }}
        />
        {ctaUrl && (
          <Link href={ctaUrl} passHref legacyBehavior>
            <a
              target={ctaUrl}
              className={`a-full-width-image-banner-link cta-link ${lang === 'fr' ? 'cta-link-fr' : lang === 'es' ? 'cta-link-es' : ''} ${emphasizedTextColor} ${isHoverActive ? ' hovered' : ''}`}
              onClick={handleCTAInteraction}
            >
              {ctaText}
            </a>
          </Link>
        )}
      </div>
    </div>
  ) : (
    ''
  );
};
